import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.reactiveValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reactiveValue) = $event)),
    placeholder: this.placeholder,
    name: "entityId",
    onChange: _ctx.onChange,
    clearable: "",
    filterable: "",
    class: _normalizeClass(['w-100'])
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entityList, (entity) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: entity[_ctx.optionKey],
          label: entity[_ctx.optionLabel].toString(),
          value: entity[_ctx.optionKey]
        }, {
          default: _withCtx(() => [
            (entity.icon)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass([entity.icon, "me-3"])
                }, null, 2))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(entity[_ctx.optionLabel]), 1)
          ]),
          _: 2
        }, 1032, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "placeholder", "onChange"]))
}