
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ApiService from "@/core/services/ApiService";
import EntityInput from "@/components/EntityInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";

export default defineComponent({
  name: "employee-form",
  props: {
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  components: {
    EntitySelect,
    EntityInput,
  },
  data() {
    return {
      errors: {},
      loading: false,
      entityType: "offer",
      entityData: {},
      statsData: {},
      verticalList: {},
      countryList: {},
      visibilityList: [
        {
          label: "Public",
          key: "public",
        },
        {
          label: "Private",
          key: "private",
        },
        {
          label: "VIP",
          key: "vip",
        },
      ],
    };
  },

  computed: {
    Title() {
      return this.mode == "new" ? "New Offer" : "Offer Edit";
    },
  },
  methods: {
    catchErrors(errors) {
      Swal.fire({
        text: "An unexpected error happened. Please contact your systems administrator.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Close",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      this.errors = errors.response.data.errors;
    },
    onSaveSuccess(response) {
      this.$router.back();
    },
    retrieveData(id) {
      this.loading = true;
      ApiService.get(this.entityType, id)
        .then((result) => {
          this.loading = false;
          this.entityData = result.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.catchErrors(err);
        });
    },
    submitData() {
      console.log(this.entityData);
      if (this.mode == "edit") {
        ApiService.update(
          this.entityType,
          this.$route.params.id.toString(),
          this.entityData
        )
          .then(this.onSaveSuccess)
          .catch(this.catchErrors);
      } else {
        ApiService.post(this.entityType, this.entityData)
          .then(this.onSaveSuccess)
          .catch(this.catchErrors);
      }
    },
  },
  mounted() {
    ApiService.query("verticals", {
      params: { perPage: 9999, filters: {} },
    }).then((response) => {
      this.verticalList = response.data.data;
    });

    ApiService.query("countries", {
      params: { perPage: 9999 },
    }).then((response) => {
      this.countryList = response.data.data;
    });
  },
  created() {
    if (this.$route.params.id) {
      this.retrieveData(this.$route.params.id);
    }

    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        if (
          toParams.id !== "" &&
          toParams.id !== "new" &&
          toParams.id !== undefined
        ) {
          this.retrieveData(toParams.id);
        }
      }
    );
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Offer Edit");
    });
  },
});
